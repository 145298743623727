export type ApplicationCountry = {
  code: string;
  name: string;
  flag?: string;
};

export const SUPPORTED_COUNTRIES: ApplicationCountry[] = [
  { code: "sv", name: "Sverige", flag: "SE" },
  { code: "en", name: "United Kingdom", flag: "GB" },
  // { code: "ar", name: "المملكة العربية السعودية", flag: "SA" },
  { code: "da", name: "Danmark", flag: "DK" },
  { code: "de", name: "Deutschland", flag: "DE" },
  { code: "fi", name: "Suomi", flag: "FI" },
  { code: "fr", name: "France", flag: "FR" },
  // { code: "ku", name: "عێراق", flag: "IQ" },
  // { code: "pl", name: "Polska", flag: "PL" },
  // { code: "so", name: "Soomaaliya", flag: "SO" },
  // { code: "ti", name: "ኤርትራ", flag: "ER" },
  // { code: "vi", name: "Việt Nam", flag: "VN" },
  // { code: "zh", name: "中国", flag: "CN" },
  { code: "no", name: "Norge", flag: "NO" },
  // { code: "fa", name: "ایران", flag: "IR" },
  // { code: "fil", name: "Pilipinas", flag: "PH" },
];

export function getCountryByCode(code: string): ApplicationCountry {
  return SUPPORTED_COUNTRIES.find((country) => country.code === code) || { code: "default", name: "" };
}

export default ApplicationCountry;
