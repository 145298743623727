import React, { useState } from "react";
import {
  Box,
  Card,
  Paper,
  Typography,
  Avatar,
  Button,
  CardHeader,
  CardContent,
  List,
  ListSubheader,
  ListItemButton,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";
import { ConfirmActionDialog } from "../../components/ConfirmActionDialog";
import { BookingModal, BookingMode, BookingType } from "./BookModal";
import { navigateToApplicantDetails } from "../../Routes";
import { SendOutTime } from "../../types/SendOutTime";
import { groupSendOutsByDay, deleteSendOutTime, fetchSendOutTimes } from "./PlanningRepository";
import { setSendOutTimes } from "../../reducers/planningPageSlice";
import { formatDateToSwe } from "../../utils/DateUtils";

interface SendOutTabProps {
  sendOutTimes: SendOutTime[];
  selectedSendOutTime: SendOutTime | undefined;
}

export const SendOutTab: React.FC<SendOutTabProps> = ({ sendOutTimes, selectedSendOutTime }) => {
  const [selected, setSelected] = useState<SendOutTime | undefined>(selectedSendOutTime);

  return (
    <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
      <SendOutList sendOutTimes={sendOutTimes} setSelectedSendOut={setSelected} />
      <Card sx={{ p: 5, position: "sticky", top: 30 }} style={{ width: "48%", alignSelf: "baseline" }}>
        {selected ? <SendOutsCard sendOutTime={selected} setSelectedSendOut={setSelected} /> : <EmptySendOutsCard />}
      </Card>
    </Box>
  );
};

interface SendOutListProps {
  sendOutTimes: SendOutTime[];
  setSelectedSendOut: (sendOut: SendOutTime | undefined) => void;
}

const SendOutList: React.FC<SendOutListProps> = ({ sendOutTimes, setSelectedSendOut }) => {
  const groupedSendOuts = groupSendOutsByDay(sendOutTimes);
  return (
    <List component={Paper} style={{ width: "48%" }}>
      {Object.keys(groupedSendOuts).map((dateStr) => (
        <li key={dateStr}>
          <ListSubheader style={{ zIndex: 1000 }}>{formatDateToSwe(dateStr)}</ListSubheader>
          {groupedSendOuts[dateStr].map((sendOut, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                setSelectedSendOut(sendOut);
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ pr: 15 }}
                style={{ width: "100%" }}
              >
                <Typography>{sendOut.applicantFullName}</Typography>
                <Typography>{sendOut.sendOut.title}</Typography>
              </Box>
            </ListItemButton>
          ))}
        </li>
      ))}
    </List>
  );
};

interface SendOutsCardProps {
  sendOutTime: SendOutTime;
  setSelectedSendOut: (sendOut: SendOutTime | undefined) => void;
}

const SendOutsCard: React.FC<SendOutsCardProps> = ({ sendOutTime, setSelectedSendOut }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [removeSendOutModal, setRemoveSendOutModal] = React.useState(false);
  const [bookingModal, setBookingModal] = useState(false);
  const jobCoachId = useAppSelector((state) => state.user.user?.jobCoachId);

  const handleRemoveSendOut = async (sendOutTimeId: string) => {
    try {
      await deleteSendOutTime(sendOutTimeId);
      setSelectedSendOut(undefined);
      const data = await fetchSendOutTimes(jobCoachId);
      dispatch(setSendOutTimes(data));
      dispatch(
        showSnackbar({
          message: t("planningPage.sendoutRemoved"),
        }),
      );
      setRemoveSendOutModal(false);
    } catch (error) {
      console.error("Error removing send out:", error);
      dispatch(
        showSnackbar({
          message: t("planningPage.removeSendoutError"),
          severity: "error",
        }),
      );
    }
  };

  return (
    <div>
      <ConfirmActionDialog
        open={removeSendOutModal}
        title={t("planningPage.removeSendoutConfirm")}
        description={`${t("planningPage.removeSendoutDescription")} ${sendOutTime.sendOut.title} t("common.to") ${sendOutTime.applicantFullName}`}
        onClose={() => {
          setRemoveSendOutModal(false);
        }}
        onAction={() => handleRemoveSendOut(sendOutTime.id)}
      />

      <BookingModal
        booking={{ mode: BookingMode.Edit, type: BookingType.SendOut, sendOutTime: sendOutTime }}
        isOpen={bookingModal}
        handleClose={() => {
          setBookingModal(false);
        }}
      />

      <CardHeader title={sendOutTime.sendOut.title} subheader={`${formatTime(sendOutTime.sendTimeStart)}`} />
      <CardContent sx={{ mt: -2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Avatar alt={sendOutTime.applicantFullName} src={sendOutTime.applicantIconUrl} />
            <Box sx={{ ml: 4 }}>
              <Typography variant="h6">{sendOutTime.applicantFullName}</Typography>
              <Typography variant="body2">{formatTime(sendOutTime.sendTimeStart)}</Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigateToApplicantDetails(navigate, sendOutTime.applicantId);
            }}
          >
            {t("planningPage.seeDetails")}
          </Button>
        </Box>
        <Box display="flex" flexDirection="row" sx={{ mt: 6 }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "12" }}
            onClick={() => {}}
          >
            {t("planningPage.download")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "12" }}
            onClick={() => {
              setBookingModal(true);
            }}
          >
            {t("planningPage.edit")}
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "12" }}
            onClick={() => {
              setRemoveSendOutModal(true);
            }}
          >
            {t("planningPage.remove")}
          </Button>
        </Box>
      </CardContent>
    </div>
  );
};

const EmptySendOutsCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      minHeight="200px"
      padding={4}
    >
      <AssignmentIcon style={{ fontSize: 80 }} />
      <Typography variant="h6" gutterBottom>
        {t("planningPage.chooseSendout")}
      </Typography>
    </Box>
  );
};

const formatTime = (dateStr: string): string => {
  const date = new Date(dateStr);
  return date.toLocaleTimeString("se-SE", { hour: "2-digit", minute: "2-digit" });
};
