import React, { useState, useEffect, useContext } from "react";
import {
  Avatar,
  Box,
  Grid,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Button,
  Fab,
  useMediaQuery,
  Theme,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import { green } from "@mui/material/colors";
import DisplayedValue from "../../components/DisplayedValue";
import {
  applicantGotJob,
  downloadApplication,
  getApplicantDetails,
  removeApplicant,
  sendEmail,
} from "./ApplicantsDetailRepository";
import { ApplicantDetails } from "../../types/ApplicantDetails";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import { calculateAgeFromDate, formatDateToString } from "../../utils/DateUtils";
import { useParams } from "react-router-dom";
import { ConfirmActionDialog } from "../../components/ConfirmActionDialog";
import MaijaEmptyState from "../../components/MaijaEmptyState";
import { useNavigate } from "react-router-dom";
import { APPLICANT_LIST_ROUTE } from "../../Routes";
import Lottie from "lottie-react";
import clebrateAnimation from "../../assets/lottie/celebrate4.json";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setApplicant } from "../../reducers/applicantDetailsPageSlice";
import { showSnackbar } from "../../reducers/snackbarSlice";
import MaijaErrorPage from "../../components/MaijaErrorPage";
import MaijaSendEmailModal from "../../components/MaijaSendEmailModal";
import { BookingModal, BookingMode, BookingType } from "../planning/BookModal";
import MaijaContainer from "../../components/MaijaContainer";
import { downloadBase64File } from "../../utils/FileUtil";
import { CloudDownload, Description, Timeline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import GraphVisualizer from "./GraphVisualizer";
import NotesComponent from "./NotesComponent";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NoteIcon from "@mui/icons-material/Note";
import InfoIcon from "@mui/icons-material/Info";
import { SuccessParamters } from "./SuccessParameters";
import { Experiences } from "./VerticalTimeline";
import ChangeJobCoachComponent from "./ChangeJobCoachComponent";
import ChangeOfficeComponent from "./ChangeOfficeComponent";
import EditIcon from "@mui/icons-material/Edit";
import { SecurityLevel } from "../../types/User";
import MaijaExperienceTimeline from "../../components/MaijaExperienceTimeline";
import ApplicantReportPreviewModal from "./ApplicantReportPreviewModal";
import { ApplicantReportsComponent } from "./ApplicantReportsComponent";
import { ExperiencesCV } from "./ExperiencesCV";
import ApplicantSupportInfoComponent from "./ApplicantSupportInfoComponent";
import PlatsbankenJobsCountAccordion from "./PlatsbankenJobsCountAccordion";
import { AuthContext } from "../../providers/AuthProvider";
import { PreviewModalMode } from "../../components/ReportPreviewModal";

const ApplicantDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const isCompanyAdmin = user?.securityLevel === SecurityLevel.CompanyAdmin;
  const applicant = useAppSelector((state) => (id ? state.applicantDetailsPageSlice.applicantsMap[id] : undefined));
  const [applicantReportModalOpen, setApplicantReportModalOpen] = useState(false);
  const [applicantReportModalMode, setApplicantReportModalMode] = useState<PreviewModalMode>(PreviewModalMode.GENERATE);
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [bookingModalOpen, setBookingModalOpen] = useState(false);
  const [removeApplicantModalOpen, setRemoveApplicantModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [gotJobAnimation, setGotJobAnimation] = useState(false);
  const [isEditingJobCoach, setIsEditingJobCoach] = useState(false);
  const [isEditingOffice, setIsEditingOffice] = useState(false);
  const toggleJobCoachEdit = () => {
    setIsEditingJobCoach((prev) => !prev);
  };
  const toggleOfficeEdit = () => {
    setIsEditingOffice((prev) => !prev);
  };
  const [jobCoachName, setJobCoachName] = useState(applicant?.jobCoachName || "");
  const [officeName, setOfficeName] = useState(applicant?.officeName || "");
  const handleJobCoachChange = (newJobCoachName: string) => {
    setJobCoachName(newJobCoachName);
    setIsEditingJobCoach(false);
  };
  const handleOfficeChange = (newOfficeName: string) => {
    setOfficeName(newOfficeName);
    setIsEditingOffice(false);
  };
  const handleOpenApplicantReportModal = (reportId: string | null) => {
    if (reportId) {
      setApplicantReportModalMode(PreviewModalMode.LOAD_EXISTING(reportId));
    } else {
      setApplicantReportModalMode(PreviewModalMode.GENERATE);
    }
    setApplicantReportModalOpen(true);
  };

  const navigate = useNavigate();

  const handleRemoveApplicant = async (applicantId: string) => {
    try {
      await removeApplicant(applicantId);
      navigate(APPLICANT_LIST_ROUTE.path);
      dispatch(
        showSnackbar({
          message: t("applicantDetails.applicantRemoved"),
        }),
      );
    } catch (error) {
      console.error("Error removing applicant:", error);
      dispatch(
        showSnackbar({
          message: t("applicantDetails.failedToRemoveApplicant"),
          severity: "error",
        }),
      );
    }
  };

  const [experienceTab, setExperienceTab] = useState(0);

  useEffect(() => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    getApplicantDetails(id)
      .then((data) => {
        if (data) {
          dispatch(setApplicant(data));
        }
      })
      .finally(() => setIsLoading(false));
  }, [id, dispatch]);

  useEffect(() => {
    if (applicant) {
      setJobCoachName(applicant.jobCoachName);
      setOfficeName(applicant.officeName);
    }
  }, [applicant]);

  if (isLoading) {
    return (
      <MaijaContainer style={{ height: "100%" }}>
        <MaijaLoadingPage isFullscreen={true} />
      </MaijaContainer>
    );
  }

  if (!applicant) {
    return (
      <MaijaContainer style={{ height: "100%" }}>
        <MaijaErrorPage isFullscreen={true} />
      </MaijaContainer>
    );
  }

  return (
    <MaijaContainer>
      <Fab
        variant="extended"
        color="secondary"
        style={{
          position: "fixed",
          bottom: "60px",
          right: "60px",
        }}
        onClick={() => handleOpenApplicantReportModal(null)}
      >
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("applicantDetails.generateReport")}</Typography>
      </Fab>

      {gotJobAnimation && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9998,
          }}
        >
          <Lottie
            animationData={clebrateAnimation}
            loop={false}
            autoplay={true}
            style={{ height: "100vh", width: "100vw" }}
            onComplete={() => {
              setGotJobAnimation(false);
            }}
          />
        </Box>
      )}

      <ApplicantReportPreviewModal
        applicantId={applicant.applicantId}
        isOpen={applicantReportModalOpen}
        reportMode={applicantReportModalMode}
        handleClose={() => {
          setApplicantReportModalOpen(false);
          setApplicantReportModalMode(PreviewModalMode.GENERATE);
        }}
      />

      <MaijaSendEmailModal
        sendToName={applicant.contactInformation.firstName}
        isOpen={sendEmailModalOpen}
        onSendEmail={(subject, body) => sendEmail(applicant.applicantId, subject, body)}
        onSuccess={() =>
          dispatch(
            showSnackbar({
              message: t("applicantDetails.emailSent"),
            }),
          )
        }
        handleClose={() => setSendEmailModalOpen(false)}
      />
      <ConfirmActionDialog
        open={removeApplicantModalOpen}
        title={t("applicantDetails.deleteApplicant", {
          name: applicant.contactInformation.fullName,
        })}
        description={t("applicantDetails.removeApplicantConfirmation", {
          firstName: applicant.contactInformation.firstName,
        })}
        onClose={() => setRemoveApplicantModalOpen(false)}
        onAction={() => handleRemoveApplicant(applicant.applicantId)}
      />
      <BookingModal
        booking={{ mode: BookingMode.Create, type: BookingType.Meeting }}
        applicantName={{
          applicantId: applicant.applicantId,
          firstName: applicant.contactInformation.firstName,
          lastName: applicant.contactInformation.lastName,
          fullName: applicant.contactInformation.fullName,
        }}
        isOpen={bookingModalOpen}
        handleClose={() => {
          setBookingModalOpen(false);
        }}
      />
      <Paper elevation={3} sx={{ m: 4, p: 4 }}>
        <ApplicantInfomation
          applicantDetails={applicant}
          onGotJob={() => {
            setGotJobAnimation(true);
            applicantGotJob(applicant.applicantId, true).then((_) => {
              getApplicantDetails(applicant.applicantId).then((data) => {
                if (data) {
                  dispatch(setApplicant(data));
                }
              });
            });
          }}
          onSendEmail={() => setSendEmailModalOpen(true)}
          onBookMeeting={() => setBookingModalOpen(true)}
          onRemove={() => setRemoveApplicantModalOpen(true)}
        />

        {isCompanyAdmin && (
          <Box sx={{ ml: 2, mt: 3, display: "flex", flexDirection: "column", gap: 2, alignItems: "flex-start" }}>
            <Box display="inline-flex" alignItems="center">
              <DisplayedValue name={t("applicantDetails.jobCoach")} value={jobCoachName} />
              <IconButton onClick={toggleJobCoachEdit} size="small" sx={{ ml: 1 }}>
                <EditIcon />
              </IconButton>
            </Box>
            {isEditingJobCoach && (
              <Box sx={{ width: "50%" }}>
                <ChangeJobCoachComponent applicantId={applicant.applicantId} onJobCoachChange={handleJobCoachChange} />
              </Box>
            )}
            <Box display="inline-flex" alignItems="center">
              <DisplayedValue name={t("applicantDetails.office")} value={officeName} />
              <IconButton onClick={toggleOfficeEdit} size="small" sx={{ ml: 1 }}>
                <EditIcon />
              </IconButton>
            </Box>
            {isEditingOffice && (
              <Box sx={{ width: "50%" }}>
                <ChangeOfficeComponent applicantId={applicant.applicantId} onOfficeChange={handleOfficeChange} />
              </Box>
            )}
          </Box>
        )}

        <Divider sx={{ mt: 2, mb: 2 }} />

        <PlatsbankenJobsCountAccordion t={t} applicantId={applicant.applicantId} />

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <CheckCircleIcon
              sx={{
                mr: 3,
                fontSize: 32,
                color: "rgba(0, 0, 0, 0.54)",
                alignSelf: "center",
              }}
            />
            <Typography variant="h5">{t("applicantDetails.successParameters")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SuccessParamters applicantDetails={applicant} />
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Timeline
              sx={{
                mr: 3,
                fontSize: 32,
                color: "rgba(0, 0, 0, 0.54)",
                alignSelf: "center",
              }}
            />
            <Typography variant="h5">{t("applicantDetails.experienceTimeline")}</Typography>
          </AccordionSummary>
          {applicant.workExperiences.length === 0 && applicant.educations.length === 0 ? (
            <MaijaEmptyState message={t("applicantDetails.noExperiences")} />
          ) : (
            <AccordionDetails>
              <Tabs value={experienceTab} onChange={(event, newValue) => setExperienceTab(newValue)}>
                <Tab label={t("applicantDetails.timelineTab")} />
                <Tab label={t("applicantDetails.experiencesTab")} />
                <Tab label={t("applicantDetails.cvTab")} />
              </Tabs>
              {experienceTab === 0 && (
                <Box sx={{ mt: 2 }}>
                  <MaijaExperienceTimeline
                    workExperiences={applicant.workExperiences}
                    educations={applicant.educations}
                  />
                </Box>
              )}
              {experienceTab === 1 && (
                <Box sx={{ mt: 2 }}>
                  <Experiences applicantDetails={applicant} />
                </Box>
              )}
              {experienceTab === 2 && (
                <Box sx={{ mt: 2 }}>
                  <ExperiencesCV applicantDetails={applicant} />
                </Box>
              )}
            </AccordionDetails>
          )}
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <NoteIcon
              sx={{
                mr: 3,
                fontSize: 32,
                color: "rgba(0, 0, 0, 0.54)",
                alignSelf: "center",
              }}
            />
            <Typography variant="h5">{t("applicantDetails.notes")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <NotesComponent applicantId={applicant.applicantId} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Description
              sx={{
                mr: 3,
                fontSize: 32,
                color: "rgba(0, 0, 0, 0.54)",
                alignSelf: "center",
              }}
            />
            <Typography variant="h5">{t("applicantDetails.reports")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ApplicantReportsComponent
              applicantId={applicant.applicantId}
              onShowReport={(reportId) => {
                handleOpenApplicantReportModal(reportId);
              }}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <AssignmentIcon
              sx={{
                mr: 3,
                fontSize: 32,
                color: "rgba(0, 0, 0, 0.54)",
                alignSelf: "center",
              }}
            />
            <Typography variant="h5">{t("applicantDetails.applications")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GraphVisualizer applicantId={applicant.applicantId} />
            <Box sx={{ height: 36 }} />
            <Applications applicantDetails={applicant} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <InfoIcon
              sx={{
                mr: 3,
                fontSize: 32,
                color: "rgba(0, 0, 0, 0.54)",
                alignSelf: "center",
              }}
            />
            <Typography variant="h5">{t("applicantDetails.applicantSupportInfo")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ApplicantSupportInfoComponent applicantId={applicant.applicantId} />
          </AccordionDetails>
        </Accordion>
      </Paper>
    </MaijaContainer>
  );
};

interface ApplicantInfomationProps {
  applicantDetails: ApplicantDetails;
  onGotJob: () => void;
  onSendEmail: () => void;
  onBookMeeting: () => void;
  onRemove: () => void;
}

const ApplicantInfomation: React.FC<ApplicantInfomationProps> = ({
  applicantDetails,
  onGotJob,
  onSendEmail,
  onBookMeeting,
  onRemove,
}) => {
  const avatarSize = GetAvatarSize();
  const { t } = useTranslation();
  let fullAddress = null;
  if (
    applicantDetails.contactInformation.address &&
    applicantDetails.contactInformation.zipCode &&
    applicantDetails.contactInformation.city
  ) {
    fullAddress = `${applicantDetails.contactInformation.address},\n  ${applicantDetails.contactInformation.zipCode} ${applicantDetails.contactInformation.city}`;
  }
  return (
    <Grid container>
      <Grid
        item
        xs={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px 0",
        }}
      >
        <Avatar
          sx={{ width: 170, height: 170 }}
          alt={applicantDetails.contactInformation.fullName}
          src={applicantDetails.profileIconUrl || undefined}
          style={{ width: avatarSize, height: avatarSize }}
        />
      </Grid>

      <Grid
        item
        xs
        container
        direction="column"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Typography variant="h5"> </Typography> {/* Adding space between top and name */}
        <Box display="flex" flexDirection="column">
          <Typography variant="h5">{applicantDetails.contactInformation.fullName}</Typography>
          {applicantDetails.birthdate && (
            <Typography variant="subtitle2">
              {calculateAgeFromDate(applicantDetails.birthdate)} {t("applicantDetails.years")}
            </Typography>
          )}
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
          {applicantDetails.hasJob && (
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
              <CheckCircleIcon style={{ fontSize: 30, color: green[500] }} />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {t("applicantDetails.foundJob")}
              </Typography>
            </Box>
          )}
        </Box>
        <Box flexDirection="row" sx={{ mt: 3, mb: 3 }}>
          <Button
            variant="contained"
            color="success"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "12" }}
            onClick={onGotJob}
            disabled={applicantDetails.hasJob}
            disableElevation
          >
            {t("applicantDetails.gotJob")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "12" }}
            onClick={onSendEmail}
          >
            {t("applicantDetails.sendEmail")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "12" }}
            onClick={onBookMeeting}
          >
            {t("applicantDetails.bookMeeting")}
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "12" }}
            onClick={onRemove}
            data-cy="remove-applicant"
          >
            {t("applicantDetails.remove")}
          </Button>
        </Box>
      </Grid>

      <Grid item container xs={3}>
        {/* TODO: Fix space-evenly */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="space-evenly"
          alignContent="space-evenly"
          justifyContent="center"
        >
          <DisplayedValue
            name={t("applicantDetails.phoneNumber")}
            value={applicantDetails.contactInformation.phoneNumber}
          />
          <DisplayedValue name={t("applicantDetails.email")} value={applicantDetails.contactInformation.email} />
          <DisplayedValue name={t("applicantDetails.address")} value={fullAddress} />
        </Box>
      </Grid>
    </Grid>
  );
};

interface ApplicationsProps {
  applicantDetails: ApplicantDetails;
}

const Applications: React.FC<ApplicationsProps> = ({ applicantDetails }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [downloading, setDownloading] = React.useState<{
    [key: string]: boolean;
  }>({});

  const handleDownloadApplication = (applicationId: string) => {
    setDownloading((prev) => ({ ...prev, [applicationId]: true }));
    downloadApplication(applicationId)
      .then((data) => {
        if (data) {
          downloadBase64File(data.resume, `Resume_${data.application.workplace}`);
          downloadBase64File(data.coverLetter, `Cover_letter_${data.application.workplace}`);
        }
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            message: "An error occurred while downloading, try again.",
            severity: "error",
          }),
        );
      })
      .finally(() => setDownloading((prev) => ({ ...prev, [applicationId]: false })));
  };

  const emptyStateComponent = <MaijaEmptyState message={t("applicantDetails.noApplications")} />;
  const listComponent = (
    <List style={{ width: "100%" }}>
      <ListItem key={"header"}>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant="h6" fontWeight="bold">
              {t("applicantDetails.companyName")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6" fontWeight="bold">
              {t("applicantDetails.applicationStatus")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" fontWeight="bold">
              {t("applicantDetails.dateApplied")}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>

      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          overflowY: "auto",
        }}
      >
        {applicantDetails.appliedJobs.map((appliedJob, index) => (
          <ListItemButton
            key={appliedJob.id}
            onClick={() => handleDownloadApplication(appliedJob.id)}
            sx={{ backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#ffffff" }}
          >
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="body1">{appliedJob.companyName}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">{appliedJob.appliedDate ? "Applied" : "Not Applied"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">{formatDateToString(appliedJob.appliedDate)}</Typography>
              </Grid>
              <Grid item xs />
              <Grid item xs={2}>
                <Typography variant="body1">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={
                      downloading[appliedJob.id] ? <CircularProgress size={24} color="inherit" /> : <CloudDownload />
                    }
                    disabled={downloading[appliedJob.id]}
                  >
                    {t("applicantDetails.download")}
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </ListItemButton>
        ))}
      </Box>
    </List>
  );

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="flex-start"
      flexDirection={"column"}
      width="100%"
      sx={{ my: 4 }}
    >
      <Box sx={{ height: 400, overflow: "auto", width: "100%" }}>
        {applicantDetails.appliedJobs.length === 0 ? emptyStateComponent : listComponent}
      </Box>
    </Box>
  );
};

function GetAvatarSize() {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.between("md", "lg"));
  let avatarSize;
  if (isXSmall) {
    avatarSize = 80;
  } else if (isSmall) {
    avatarSize = 100;
  } else {
    avatarSize = 120;
  }
  return avatarSize;
}

export default ApplicantDetailsPage;
