import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Meeting, MeetingType } from "../../types/Meeting";
import { SendOutTime } from "../../types/SendOutTime";
import { EventContentArg, EventInput } from "@fullcalendar/core";
import { useTranslation } from "react-i18next";

interface CalendarTabProps {
  meetings: Meeting[];
  sendOutTimes: SendOutTime[];
}

export const CalendarTab: React.FC<CalendarTabProps> = ({ meetings, sendOutTimes }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const monthNamesLong = [
    t("calendar.monthNames.january"),
    t("calendar.monthNames.february"),
    t("calendar.monthNames.march"),
    t("calendar.monthNames.april"),
    t("calendar.monthNames.may"),
    t("calendar.monthNames.june"),
    t("calendar.monthNames.july"),
    t("calendar.monthNames.august"),
    t("calendar.monthNames.september"),
    t("calendar.monthNames.october"),
    t("calendar.monthNames.november"),
    t("calendar.monthNames.december"),
  ];

  const monthNamesShort = [
    t("calendar.monthNamesShort.jan"),
    t("calendar.monthNamesShort.feb"),
    t("calendar.monthNamesShort.mar"),
    t("calendar.monthNamesShort.apr"),
    t("calendar.monthNamesShort.may"),
    t("calendar.monthNamesShort.jun"),
    t("calendar.monthNamesShort.jul"),
    t("calendar.monthNamesShort.aug"),
    t("calendar.monthNamesShort.sep"),
    t("calendar.monthNamesShort.oct"),
    t("calendar.monthNamesShort.nov"),
    t("calendar.monthNamesShort.dec"),
  ];

  const events: EventInput[] = [];

  const parseDate = (dateStr: string): string => {
    const date = new Date(dateStr);
    return date.toISOString();
  };

  meetings.forEach((meeting) => {
    events.push({
      title: meeting.applicantFullName,
      start: parseDate(meeting.startTime),
      end: parseDate(meeting.endTime),
      color: theme.palette.primary.main,
      extendedProps: {
        meetingType: meeting.type,
        description: `${meeting.applicantFullName}\n${
          meeting.type === MeetingType.Physical ? t("calendar.physicalMeeting") : t("calendar.virtualMeeting")
        }\n${formatTime(meeting.startTime)} - ${formatTime(meeting.endTime)}`,
      },
    });
  });

  sendOutTimes.forEach((sendOut) => {
    events.push({
      title: `${sendOut.applicantFullName} - ${t("calendar.sendout")}: ${sendOut.sendOut.title}`,
      start: parseDate(sendOut.sendTimeStart),
      end: sendOut.sendTimeEnd ? parseDate(sendOut.sendTimeEnd) : parseDate(sendOut.sendTimeStart),
      color: theme.palette.secondary.main,
      extendedProps: {
        description: `${t("calendar.applicant")}: ${sendOut.applicantFullName}\n${t("calendar.sendout")}: ${
          sendOut.sendOut.title
        }\n${t("common.time")}: ${formatTime(sendOut.sendTimeStart)} - ${formatTime(
          sendOut.sendTimeEnd || sendOut.sendTimeStart,
        )}`,
      },
    });
  });

  const eventStyle: React.CSSProperties = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const [slotHeight, setSlotHeight] = useState<number | null>(null);

  useEffect(() => {
    const slotEls = document.querySelectorAll(".fc-timegrid-slot");
    if (slotEls.length > 0) {
      const height = slotEls[0].getBoundingClientRect().height;
      setSlotHeight(height);
    }
  }, []);

  return (
    <Box
      sx={{
        pt: 2,
        mx: 5,
        "& .fc .fc-button": {
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        "& .fc .fc-button:hover": {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.contrastText,
        },
        "& .fc .fc-button.fc-button-active": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
      }}
    >
      <FullCalendar
        plugins={[timeGridPlugin, dayGridPlugin]}
        initialView="timeGridWeek"
        firstDay={1}
        events={events}
        slotDuration="00:30:00"
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          hour12: false,
        }}
        eventTimeFormat={{
          hour: "numeric",
          minute: "2-digit",
          hour12: false,
        }}
        businessHours={{
          daysOfWeek: [1, 2, 3, 4, 5],
          startTime: "08:00",
          endTime: "17:00",
        }}
        allDaySlot={false}
        eventContent={(eventInfo: EventContentArg) => (
          <div style={{ display: "flex", flexDirection: "column", overflow: "hidden", height: "100%" }}>
            <div
              style={{
                ...eventStyle,
                flex: "0 0 auto",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              title={eventInfo.event.extendedProps.description}
            >
              <strong>{eventInfo.event.title}</strong>
            </div>
            {eventInfo.event.extendedProps.meetingType && (
              <div style={{ flex: "1 1 auto" }}>
                {eventInfo.event.extendedProps.meetingType === MeetingType.Physical
                  ? t("calendar.physicalMeeting")
                  : t("calendar.virtualMeeting")}
              </div>
            )}
            <div style={{ flex: "1 1 auto" }}>{eventInfo.timeText}</div>
          </div>
        )}
        eventDidMount={(info) => {
          if (slotHeight) {
            (info.el as HTMLElement).style.minHeight = `${slotHeight}px`;
          }
        }}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        buttonText={{
          today: t("calendar.today"),
          month: t("calendar.month"),
          week: t("calendar.week"),
          day: t("calendar.day"),
        }}
        titleFormat={(arg) => {
          const start = arg.start;
          return `${monthNamesLong[start.month]} ${start.year}`;
        }}
        dayHeaderContent={(arg) => {
          const jsDate = arg.date as Date;
          return `${monthNamesShort[jsDate.getMonth()]} ${jsDate.getDate()}`;
        }}
      />
    </Box>
  );
};

const formatTime = (dateStr: string): string => {
  const date = new Date(dateStr);
  return date.toLocaleTimeString("se-SE", { hour: "2-digit", minute: "2-digit" });
};
