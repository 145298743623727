import React, { useState, useEffect } from "react";
import { Button, Box, Typography, Autocomplete, TextField, ButtonGroup } from "@mui/material";
import { TimePicker, DatePicker } from "@mui/x-date-pickers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Meeting, MeetingType } from "../../types/Meeting";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import {
  setMeetings,
  setSelectedMeeting,
  setSelectedSendOutTime,
  setSendOutTimes,
} from "../../reducers/planningPageSlice";
import MaijaModal from "../../components/MaijaModal";
import { ApplicantName } from "../../types/ApplicantName";
import { SendOutTime } from "../../types/SendOutTime";
import {
  bookMeeting,
  bookSendOutTime,
  editMeeting,
  editSendOutTime,
  fetchMeetings,
  fetchSendOutTimes,
  MeetingRequestData,
  SendOutTimeRequestData,
} from "./PlanningRepository";
import dayjs, { Dayjs } from "dayjs";
import { SendOut } from "../../types/SendOut";
import { useTranslation } from "react-i18next";
import { Person } from "@mui/icons-material";

export interface BookingModalProps {
  booking: Booking;
  applicantName?: ApplicantName;
  isOpen: boolean;
  handleClose: () => void;
}

export enum BookingType {
  Meeting,
  SendOut,
}

export enum BookingMode {
  Create,
  Edit,
}

export type Booking =
  | { mode: BookingMode.Create; type: BookingType }
  | { mode: BookingMode.Edit; type: BookingType.Meeting; meeting: Meeting }
  | { mode: BookingMode.Edit; type: BookingType.SendOut; sendOutTime: SendOutTime };

const getInitialDate = (booking: Booking): Dayjs | null => {
  if (booking.mode === BookingMode.Edit && booking.type === BookingType.Meeting) {
    return dayjs(booking.meeting.startTime);
  }
  if (booking.mode === BookingMode.Edit && booking.type === BookingType.SendOut) {
    return dayjs(booking.sendOutTime.sendTimeStart);
  }
  return null;
};

const getInitialStartTime = (booking: Booking): Dayjs | null => {
  if (booking.mode === BookingMode.Edit && booking.type === BookingType.Meeting) {
    return dayjs(booking.meeting.startTime);
  }
  return null;
};

const getInitialEndTime = (booking: Booking): Dayjs | null => {
  if (booking.mode === BookingMode.Edit && booking.type === BookingType.Meeting) {
    return dayjs(booking.meeting.endTime);
  }
  return null;
};

const getInitialSendOutTime = (booking: Booking): Dayjs | null => {
  if (booking.mode === BookingMode.Edit && booking.type === BookingType.SendOut) {
    return dayjs(booking.sendOutTime.sendTimeStart);
  }
  return null;
};

const getInitialSendOutEndTime = (booking: Booking): Dayjs | null => {
  if (booking.mode === BookingMode.Edit && booking.type === BookingType.SendOut) {
    return dayjs(booking.sendOutTime.sendTimeEnd);
  }
  return null;
};

const getInitialApplicantName = (
  booking: Booking,
  applicantNames: ApplicantName[],
  defaultApplicantName?: ApplicantName,
): ApplicantName | null => {
  if (defaultApplicantName) {
    return defaultApplicantName;
  }
  if (booking.mode === BookingMode.Edit && booking.type === BookingType.Meeting) {
    return applicantNames.find((applicantName) => applicantName.applicantId === booking.meeting?.applicantId) || null;
  }
  if (booking.mode === BookingMode.Edit && booking.type === BookingType.SendOut) {
    return (
      applicantNames.find((applicantName) => applicantName.applicantId === booking.sendOutTime?.applicantId) || null
    );
  }
  return null;
};

const getInitialSendout = (booking: Booking, sendOuts: SendOut[]): SendOut | null => {
  if (booking.mode === BookingMode.Edit && booking.type === BookingType.SendOut) {
    return sendOuts.find((sendOut) => sendOut.id === booking.sendOutTime?.sendOut.id) || null;
  }
  return null;
};

const getInitialMeetingType = (booking: Booking): MeetingType => {
  if (booking.mode === BookingMode.Edit && booking.type === BookingType.Meeting) {
    return booking.meeting.type;
  }
  return MeetingType.Physical;
};

export const BookingModal: React.FC<BookingModalProps> = ({ booking, applicantName, isOpen, handleClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const applicantNames = useAppSelector((state) => state.planningPageSlice.applicantNames);
  const sendOuts = useAppSelector((state) => state.planningPageSlice.sendOuts);

  const [selectedApplicant, setSelectedApplicant] = useState<ApplicantName | null>(null);
  const [selectedApplicantValid, setSelectedApplicantValid] = useState(true);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedDateValid, setSelectedDateValid] = useState(true);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [startTimeValid, setStartDateValid] = useState(true);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [endTimeValid, setEndTimeValid] = useState(true);
  const [sendOutTime, setSendOutTime] = useState<Dayjs | null>(null);
  const [sendOutTimeValid, setSendOutTimeValid] = useState(true);
  const [sendOutEndTime, setSendOutEndTime] = useState<Dayjs | null>(null);
  const [sendOutEndTimeValid, setSendOutEndTimeValid] = useState(true);
  const [meetingType, setMeetingType] = useState<MeetingType>(MeetingType.Physical);
  const [selectedSendOut, setSelectedSendOut] = useState<SendOut | null>(null);
  const [selectedSendOutValid, setSelectedSendOutValid] = useState(true);

  const jobCoachId = useAppSelector((state) => state.user.user?.jobCoachId);

  useEffect(() => {
    if (isOpen) {
      setSelectedApplicant(getInitialApplicantName(booking, applicantNames, applicantName));
      setSelectedSendOut(getInitialSendout(booking, sendOuts));
      setSelectedDate(getInitialDate(booking));
      setStartTime(getInitialStartTime(booking));
      setEndTime(getInitialEndTime(booking));
      setSendOutTime(getInitialSendOutTime(booking));
      setSendOutEndTime(getInitialSendOutEndTime(booking));
      setMeetingType(getInitialMeetingType(booking));
    }
  }, [isOpen, applicantName, applicantNames, booking, sendOuts]);

  const onBookClick = () => {
    setSelectedApplicantValid(selectedApplicant !== null);
    setSelectedDateValid(selectedDate !== null);
    setStartDateValid(startTime !== null);
    setEndTimeValid(endTime !== null);
    setSendOutTimeValid(sendOutTime !== null);
    setSendOutEndTimeValid(sendOutEndTime !== null);
    setSelectedSendOutValid(selectedSendOut !== null);

    if (
      booking.type === BookingType.Meeting &&
      selectedApplicant !== null &&
      selectedDate !== null &&
      startTime !== null &&
      endTime !== null
    ) {
      const requestData: MeetingRequestData = {
        applicantId: selectedApplicant.applicantId,
        startTime: selectedDate.hour(startTime.hour()).minute(startTime.minute()).toDate().toUTCString(),
        endTime: selectedDate.hour(endTime.hour()).minute(endTime.minute()).toDate().toUTCString(),
        type: meetingType,
      };

      if (booking.mode === BookingMode.Create) {
        bookMeeting(requestData, jobCoachId).then(() => {
          dispatch(
            showSnackbar({
              message: t("bookingModal.meetingBooked"),
            }),
          );
          fetchMeetings(jobCoachId).then((data) =>
            dispatch(() => {
              dispatch(setMeetings(data));
            }),
          );
        });
      } else if (booking.mode === BookingMode.Edit) {
        editMeeting(booking.meeting.id, requestData).then(() => {
          dispatch(
            showSnackbar({
              message: t("bookingModal.meetingUpdated"),
            }),
          );
          fetchMeetings(jobCoachId).then((data) => {
            dispatch(setMeetings(data));
            dispatch(setSelectedMeeting(data.find((meeting) => meeting.id === booking.meeting.id)));
          });
        });
      }
      handleClose();
    }

    if (
      booking.type === BookingType.SendOut &&
      selectedApplicant !== null &&
      selectedDate !== null &&
      sendOutTime !== null &&
      sendOutEndTime !== null &&
      selectedSendOut !== null
    ) {
      const requestData: SendOutTimeRequestData = {
        applicantId: selectedApplicant.applicantId,
        sendOutId: selectedSendOut.id,
        sendTimeStart: selectedDate.hour(sendOutTime.hour()).minute(sendOutTime.minute()).toDate().toUTCString(),
        sendTimeEnd: selectedDate.hour(sendOutEndTime.hour()).minute(sendOutEndTime.minute()).toDate().toUTCString(),
      };

      if (booking.mode === BookingMode.Create) {
        bookSendOutTime(requestData, jobCoachId).then(() => {
          dispatch(
            showSnackbar({
              message: t("bookingModal.sendOutBooked"),
            }),
          );
          fetchSendOutTimes(jobCoachId).then((data) => dispatch(setSendOutTimes(data)));
        });
      } else if (booking.mode === BookingMode.Edit) {
        editSendOutTime(booking.sendOutTime.id, requestData).then(() => {
          dispatch(
            showSnackbar({
              message: t("bookingModal.sendOutUpdated"),
            }),
          );
          fetchSendOutTimes(jobCoachId).then((data) => {
            dispatch(setSendOutTimes(data));
            dispatch(setSelectedSendOutTime(data.find((sendOutTime) => sendOutTime.id === booking.sendOutTime.id)));
          });
        });
      }
      handleClose();
    }
  };

  return (
    <MaijaModal
      isOpen={isOpen}
      handleClose={() => {
        setSelectedApplicant(null);
        setSelectedDate(null);
        setStartTime(null);
        setEndTime(null);
        setSendOutTime(null);
        setSendOutEndTime(null);
        setSelectedSendOut(null);
        handleClose();
      }}
      maxWidth="590px"
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="h5" sx={{ mb: 6 }}>
          {booking.type === BookingType.Meeting && booking.mode === BookingMode.Create && t("bookingModal.bookMeeting")}
          {booking.type === BookingType.Meeting && booking.mode === BookingMode.Edit && t("bookingModal.editMeeting")}
          {booking.type === BookingType.SendOut && booking.mode === BookingMode.Create && t("bookingModal.bookSendOut")}
          {booking.type === BookingType.SendOut && booking.mode === BookingMode.Edit && t("bookingModal.editSendOut")}
        </Typography>
        <Autocomplete
          sx={{ mb: 6 }}
          options={applicantNames}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          value={applicantName ?? selectedApplicant}
          disabled={applicantName !== undefined}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("bookingModal.selectApplicant")}
              variant="outlined"
              helperText={!selectedApplicantValid ? t("bookingModal.selectApplicantHelper") : ""}
              error={!selectedApplicantValid}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <Person />
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(_, newValue) => {
            setSelectedApplicant(newValue);
            if (!selectedApplicantValid) {
              setSelectedApplicantValid(newValue !== null);
            }
          }}
        />

        {booking.type === BookingType.SendOut && (
          <Autocomplete
            sx={{ mb: 6 }}
            options={sendOuts}
            getOptionLabel={(option) => `${option.title}`}
            value={selectedSendOut}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("bookingModal.selectSendOut")}
                variant="outlined"
                helperText={!selectedSendOutValid ? t("bookingModal.selectSendOutHelper") : ""}
                error={!selectedSendOutValid}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <AssignmentIcon />
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
            onChange={(_, newValue) => {
              setSelectedSendOut(newValue);
              if (!selectedSendOutValid) {
                setSelectedSendOutValid(newValue !== null);
              }
            }}
          />
        )}

        <Box display="flex" flexDirection="row" sx={{ mb: 6 }}>
          <DatePicker
            label={t("bookingModal.selectDate")}
            sx={{ mr: 2 }}
            value={selectedDate}
            minDate={dayjs()}
            onChange={(newValue: Dayjs | null) => {
              setSelectedDate(newValue);
              if (!selectedDateValid) {
                setSelectedDateValid(newValue !== null);
              }
            }}
            slotProps={{
              textField: {
                helperText: selectedDateValid ? "" : t("bookingModal.selectDateHelper"),
                error: !selectedDateValid,
              },
            }}
          />
          {booking.type === BookingType.Meeting && (
            <TimePicker
              ampm={false}
              label={t("bookingModal.start")}
              sx={{ mr: 2 }}
              value={startTime}
              maxTime={endTime ?? undefined}
              onChange={(newValue: Dayjs | null) => {
                setStartTime(newValue);
                if (newValue && endTime && newValue.isAfter(endTime)) {
                  setEndTime(null);
                  setEndTimeValid(false);
                }
                if (!startTimeValid) {
                  setStartDateValid(newValue !== null);
                }
              }}
              slotProps={{
                textField: {
                  helperText: startTimeValid ? "" : t("bookingModal.startHelper"),
                  error: !startTimeValid,
                },
              }}
            />
          )}
          {booking.type === BookingType.Meeting && (
            <TimePicker
              ampm={false}
              label={t("bookingModal.end")}
              sx={{ mr: 2 }}
              value={endTime}
              minTime={startTime ?? undefined}
              onChange={(newValue: Dayjs | null) => {
                if (newValue && startTime && newValue.isBefore(startTime)) {
                  setEndTimeValid(false);
                } else {
                  setEndTime(newValue);
                  setEndTimeValid(true);
                }
              }}
              slotProps={{
                textField: {
                  helperText: endTimeValid ? "" : t("bookingModal.endHelper"),
                  error: !endTimeValid,
                },
              }}
            />
          )}
          {booking.type === BookingType.SendOut && (
            <>
              <TimePicker
                ampm={false}
                label={t("bookingModal.startTime")}
                sx={{ mr: 2 }}
                value={sendOutTime}
                maxTime={sendOutEndTime ?? undefined}
                onChange={(newValue: Dayjs | null) => {
                  setSendOutTime(newValue);
                  if (!sendOutTimeValid) {
                    setSendOutTimeValid(newValue !== null);
                  }
                  if (sendOutEndTime && newValue && newValue.isAfter(sendOutEndTime)) {
                    setSendOutEndTime(null);
                    setSendOutEndTimeValid(false);
                  }
                }}
                slotProps={{
                  textField: {
                    helperText: sendOutTimeValid ? "" : t("bookingModal.startTimeHelper"),
                    error: !sendOutTimeValid,
                  },
                }}
              />
              <TimePicker
                ampm={false}
                label={t("bookingModal.endTime")}
                sx={{ mr: 2 }}
                value={sendOutEndTime}
                minTime={sendOutTime ?? undefined}
                onChange={(newValue: Dayjs | null) => {
                  if (newValue && sendOutTime && newValue.isBefore(sendOutTime)) {
                    setSendOutEndTimeValid(false);
                  } else {
                    setSendOutEndTime(newValue);
                    setSendOutEndTimeValid(true);
                  }
                }}
                slotProps={{
                  textField: {
                    helperText: sendOutEndTimeValid ? "" : t("bookingModal.endTimeHelper"),
                    error: !sendOutEndTimeValid,
                  },
                }}
              />
            </>
          )}
        </Box>
        {booking.type === BookingType.Meeting && (
          <ButtonGroup>
            <Button
              variant="contained"
              color={meetingType === MeetingType.Physical ? "secondary" : "inherit"}
              onClick={() => setMeetingType(MeetingType.Physical)}
            >
              {t("bookingModal.physical")}
            </Button>
            <Button
              variant="contained"
              color={meetingType === MeetingType.Virtual ? "secondary" : "inherit"}
              onClick={() => setMeetingType(MeetingType.Virtual)}
            >
              {t("bookingModal.virtual")}
            </Button>
          </ButtonGroup>
        )}

        <Button
          style={{ alignSelf: "center", minWidth: "150px" }}
          sx={{ mt: 6 }}
          variant="contained"
          color="primary"
          onClick={onBookClick}
        >
          {t("bookingModal.book")}
        </Button>
      </Box>
    </MaijaModal>
  );
};
