import dayjs from "dayjs";

export function formatDateToYearMonth(dateStr: string): string {
  if (!dateStr || dateStr === "-") {
    return "-";
  }
  const date = dayjs(dateStr);
  const year = date.year();
  const month = date.month() + 1; // Day.js months are 0-indexed
  return `${year} ${month.toString().padStart(2, "0")}`;
}

export function formatDateToYearMonthName(dateStr: string, t: (key: string) => string): string {
  if (!dateStr || dateStr === "-") {
    return "-";
  }
  const date = dayjs(dateStr);
  const monthName = date.format("MMMM");
  const translatedMonthName = t(`monthEnum.${monthName}`);

  return `${date.format("YYYY")} ${translatedMonthName}`;
}

export function formatDateToString(dateStr?: string): string {
  if (!dateStr || dateStr === "-") {
    return "-";
  }

  const date = dayjs(dateStr);
  const year = date.year();
  const month = (date.month() + 1).toString().padStart(2, "0");
  const day = date.date().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function formatDateTimeToString(dateStr: string): string {
  if (!dateStr || dateStr === "-") {
    return "-";
  }

  const date = dayjs(dateStr);
  const year = date.year();
  const month = (date.month() + 1).toString().padStart(2, "0");
  const day = date.date().toString().padStart(2, "0");
  const hours = date.hour().toString().padStart(2, "0");
  const minutes = date.minute().toString().padStart(2, "0");
  const seconds = date.second().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function calculateDaysAgo(date: string): number {
  const today = new Date();
  const inputDate = new Date(date);
  const diffTime = Math.abs(today.getTime() - inputDate.getTime());
  return Math.floor(diffTime / (1000 * 60 * 60 * 24));
}

export function calculateAgeFromDate(date: string): number {
  const today = new Date();
  const inputDate = new Date(date);

  let age = today.getFullYear() - inputDate.getFullYear();
  const monthDifference = today.getMonth() - inputDate.getMonth();
  const dayDifference = today.getDate() - inputDate.getDate();

  // Adjust age if the birthday hasn't occurred yet this year
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
}

export const formatDateToSwe = (dateStr: string): string => {
  const date = new Date(dateStr);
  const formattedDate = date.toLocaleDateString("sv-SE", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  return formattedDate;
};
