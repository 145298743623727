import React, { FormEvent, useState, useContext, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  useTheme,
  Paper,
  Typography,
  CircularProgress,
  useMediaQuery,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useAppDispatch } from "../../hooks";
import { setLoggedInUser } from "../../reducers/userSlice";
import { AuthContext } from "../../providers/AuthProvider";
import {
  setPrimaryColor,
  setSecondaryColor,
  setTertiaryColor,
  setLogo,
  setLoginImage,
} from "../../reducers/themeSlice";
import { login, getSettings } from "./LoginRepository";
import { useNavigate } from "react-router-dom";
import { FORGOT_PASSWORD_ROUTE, APPLICANT_LIST_ROUTE, OFFICE_LIST_ROUTE } from "../../Routes";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";
import { SecurityLevel } from "../../types/User";
import { APP_VERSION } from "../../config";

interface LoginFormData {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<LoginFormData>({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { user, isAuthenticated, setUser, setIsAuthenticated } = useContext(AuthContext);

  const isFormValid = formData.email !== "" && formData.password !== "";

  useEffect(() => {
    if (isAuthenticated && user) {
      const targetRoute =
        user.securityLevel >= SecurityLevel.CompanyAdmin ? OFFICE_LIST_ROUTE.path : APPLICANT_LIST_ROUTE.path;
      navigate(targetRoute, { replace: true });
    }
  }, [isAuthenticated, user, navigate]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!isFormValid) {
      dispatch(
        showSnackbar({
          message: t("login_page.fill_all_fields"),
          severity: "error",
        }),
      );
      return;
    }

    setIsLoading(true);
    try {
      const loggedInUser = await login({ email: formData.email, password: formData.password });

      dispatch(setLoggedInUser(loggedInUser));
      setUser(loggedInUser);
      setIsAuthenticated(true);

      const settings = await getSettings(loggedInUser.companyId);
      if (settings.primary_color) {
        dispatch(setPrimaryColor(settings.primary_color));
      }
      if (settings.secondary_color) {
        dispatch(setSecondaryColor(settings.secondary_color));
      }
      if (settings.tertiary_color) {
        dispatch(setTertiaryColor(settings.tertiary_color));
      }
      dispatch(setLogo(settings.logo ?? null));
      dispatch(setLoginImage(settings.login_image ?? null));

      const targetRoute =
        loggedInUser.securityLevel >= SecurityLevel.CompanyAdmin ? OFFICE_LIST_ROUTE.path : APPLICANT_LIST_ROUTE.path;
      navigate(targetRoute);
    } catch (errorMessage) {
      console.error(errorMessage);
      dispatch(
        showSnackbar({
          message: t("login_page.failed"),
          severity: "error",
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xl" disableGutters>
      <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} height="100vh">
        {!isSmallScreen && (
          <Box
            flex="0 0 70%"
            style={{
              backgroundImage: `linear-gradient(20deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Typography variant="h2" sx={{ color: theme.palette.primary.contrastText }}>
              {t("login_page.welcome_admin")}
            </Typography>
          </Box>
        )}
        <Box
          flex={isSmallScreen ? "1 0 auto" : "0 0 30%"}
          component={Paper}
          elevation={6}
          display="flex"
          flexDirection="column"
          alignItems="center"
          p={4}
          square
          height={isSmallScreen ? "auto" : "100%"}
        >
          <Box alignItems="flex-start" justifyContent="center" display="flex" flexDirection="column" flexGrow={1}>
            <Typography variant="h3">{t("login_page.title")}</Typography>
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label={t("login_page.email_label")}
                name="email"
                autoComplete="email"
                autoFocus
                value={formData.email}
                onChange={handleInputChange}
                data-cy="email"
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={t("login_page.password_label")}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleInputChange}
                data-cy="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t("login_page.toggle_password_visibility")}
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading}
                sx={{ mt: 3 }}
                data-cy="login"
              >
                {t("login_page.button")}
                {isLoading && <CircularProgress size={22} sx={{ ml: 3 }} />}
              </Button>
              <Button
                variant="text"
                sx={{ mt: 3 }}
                onClick={() => {
                  navigate(FORGOT_PASSWORD_ROUTE.path);
                }}
                data-cy="forgot-password"
              >
                {t("login_page.forgot_password_button")}
              </Button>
            </form>
          </Box>
          <Box sx={{ mt: 4, alignSelf: "center" }}>
            <Typography variant="body2" color="textSecondary">
              {t("login_page.version_label")}: {APP_VERSION}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
