import React, { useState } from "react";
import {
  Box,
  Card,
  Paper,
  Typography,
  Avatar,
  Button,
  CardHeader,
  CardContent,
  List,
  ListSubheader,
  ListItemButton,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import { useNavigate } from "react-router-dom";
import { Meeting, MeetingType } from "../../types/Meeting";
import { groupMeetingsByDay, deleteMeeting } from "./PlanningRepository";
import { navigateToApplicantDetails } from "../../Routes";
import { useAppDispatch } from "../../hooks";
import { removeMeeting as removeMeetingFromState } from "../../reducers/planningPageSlice";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";
import { ConfirmActionDialog } from "../../components/ConfirmActionDialog";
import { BookingModal, BookingMode, BookingType } from "./BookModal";
import { formatDateToSwe } from "../../utils/DateUtils";

interface MeetingsTabProps {
  meetings: Meeting[];
  selectedMeeting: Meeting | undefined;
}

export const MeetingsTab: React.FC<MeetingsTabProps> = ({ meetings, selectedMeeting }) => {
  const [selected, setSelected] = useState<Meeting | undefined>(selectedMeeting);

  return (
    <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
      <MeetingsList meetings={meetings} setSelectedMeeting={setSelected} />
      <Card sx={{ p: 5, position: "sticky", top: 30 }} style={{ width: "48%", alignSelf: "baseline" }}>
        {selected ? <MeetingCard meeting={selected} setSelectedMeeting={setSelected} /> : <EmptyMeetingCard />}
      </Card>
    </Box>
  );
};

interface MeetingsListProps {
  meetings: Meeting[];
  setSelectedMeeting: (meeting: Meeting | undefined) => void;
}

const MeetingsList: React.FC<MeetingsListProps> = ({ meetings, setSelectedMeeting }) => {
  const groupedMeetings = groupMeetingsByDay(meetings);
  return (
    <List component={Paper} style={{ width: "48%" }}>
      {Object.keys(groupedMeetings).map((dateStr) => (
        <li key={dateStr}>
          <ListSubheader>{formatDateToSwe(dateStr)}</ListSubheader>
          {groupedMeetings[dateStr].map((meeting, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                setSelectedMeeting(meeting);
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ pr: 15 }}
                style={{ width: "100%" }}
                data-cy="meetings-list"
              >
                <Typography>
                  {formatTime(meeting.startTime)} - {formatTime(meeting.endTime)}
                </Typography>
                <Typography>{meeting.applicantFullName}</Typography>
                <Typography></Typography>
              </Box>
            </ListItemButton>
          ))}
        </li>
      ))}
    </List>
  );
};

interface MeetingCardProps {
  meeting: Meeting;
  setSelectedMeeting: (meeting: Meeting | undefined) => void;
}

const MeetingCard: React.FC<MeetingCardProps> = ({ meeting, setSelectedMeeting }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [removeMeetingModal, setRemoveMeetingModal] = React.useState(false);
  const [bookingModal, setBookingModal] = useState(false);

  const handleRemoveMeeting = async (meetingId: string) => {
    try {
      dispatch(removeMeetingFromState(meetingId));
      await deleteMeeting(meetingId);
      setSelectedMeeting(undefined);
      dispatch(
        showSnackbar({
          message: t("planningPage.meetingRemoved"),
        }),
      );
      setRemoveMeetingModal(false);
    } catch (error) {
      console.error("Error removing meeting:", error);
      dispatch(
        showSnackbar({
          message: t("planningPage.removeMeetingError"),
          severity: "error",
        }),
      );
    }
  };

  return (
    <div>
      <ConfirmActionDialog
        open={removeMeetingModal}
        title={t("planningPage.removeMeetingConfirm")}
        description={`${t("planningPage.removeMeetingDescription")} ${meeting.applicantFullName}`}
        onClose={() => {
          setRemoveMeetingModal(false);
        }}
        onAction={() => handleRemoveMeeting(meeting.id)}
      />
      <BookingModal
        booking={{ mode: BookingMode.Edit, type: BookingType.Meeting, meeting: meeting }}
        isOpen={bookingModal}
        handleClose={() => {
          setBookingModal(false);
        }}
      />

      <CardHeader
        title={
          meeting?.type === MeetingType.Physical ? t("planningPage.physicalMeeting") : t("planningPage.virtualMeeting")
        }
      />
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Avatar alt={meeting.applicantFullName} src={meeting.applicantIconUrl} />
            <Box sx={{ ml: 4 }}>
              <Typography variant="h6">{meeting.applicantFullName}</Typography>
              <Typography variant="body2">
                {formatTime(meeting.startTime)} - {formatTime(meeting.endTime)}
              </Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigateToApplicantDetails(navigate, meeting.applicantId);
            }}
          >
            {t("planningPage.seeDetails")}
          </Button>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Typography variant="body1" fontWeight={"bold"}>
            {t("planningPage.applicantIntroduction")}
          </Typography>
          <Typography variant="body2" sx={{ mb: 5 }}>
            {meeting.applicantIntroduction}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" sx={{ mt: 6 }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "12" }}
            onClick={() => {
              setBookingModal(true);
            }}
          >
            {t("planningPage.edit")}
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ py: 2, px: 4, mr: 2, mt: 1, borderRadius: "12" }}
            onClick={() => {
              setRemoveMeetingModal(true);
            }}
          >
            {t("planningPage.delete")}
          </Button>
        </Box>
      </CardContent>
    </div>
  );
};

const EmptyMeetingCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      minHeight="200px"
      padding={4}
    >
      <GroupsIcon style={{ fontSize: 80 }} />
      <Typography variant="h6" gutterBottom>
        {t("planningPage.chooseMeeting")}
      </Typography>
    </Box>
  );
};

const formatTime = (dateStr: string): string => {
  const date = new Date(dateStr);
  return date.toLocaleTimeString("se-SE", { hour: "2-digit", minute: "2-digit" });
};
