import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  useTheme,
  Backdrop,
  CircularProgress,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";
import { updateSettings, getSettings } from "./SettingsRepository";
import { CompanySettings } from "../../types/CompanySettings";
import {
  setPrimaryColor as setReduxPrimaryColor,
  setSecondaryColor as setReduxSecondaryColor,
  setTertiaryColor as setReduxTertiaryColor,
  setLogo as setReduxLogo,
  setLoginImage as setReduxLoginImage,
} from "../../reducers/themeSlice";
import { readFileAsDataURL } from "../../utils/FileUtil";
import ApplicationCountry, { getCountryByCode, SUPPORTED_COUNTRIES } from "../../types/ApplicationCountry";
import Flag from "react-world-flags";

const SettingsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const companyId = useAppSelector((state) => state.user.user?.companyId);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState<File | null>(null);
  const [loginImage, setLoginImage] = useState<File | null>(null);
  const [country, setCountry] = useState<ApplicationCountry>(getCountryByCode("sv"));
  const primaryColor = useAppSelector((state) => state.theme.primaryColor);
  const secondaryColor = useAppSelector((state) => state.theme.secondaryColor);
  const tertiaryColor = useAppSelector((state) => state.theme.tertiaryColor);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setIsLoading(true);
        if (companyId) {
          const settings = await getSettings(companyId);
          if (settings) {
            dispatch(setReduxPrimaryColor(settings.primary_color || theme.palette.primary.main));
            dispatch(setReduxSecondaryColor(settings.secondary_color || theme.palette.secondary.main));
            dispatch(setReduxTertiaryColor(settings.tertiary_color || "#FFFFFF"));
          }
        }
      } catch (error) {
        dispatch(showSnackbar({ message: "Failed to load settings", severity: "error" }));
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
    // eslint-disable-next-line
  }, [dispatch, companyId]);

  const handleInputChange =
    (setAction: (color: string) => { type: string; payload: string }) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const color = event.target.value;
      dispatch(setAction(color));
    };

  const handleSubmit = async () => {
    setIsLoading(true);
    const data: CompanySettings = {
      primary_color: primaryColor,
      secondary_color: secondaryColor,
      tertiary_color: tertiaryColor,
    };

    if (logo) {
      data.logo = await readFileAsDataURL(logo);
    }
    if (loginImage) {
      data.login_image = await readFileAsDataURL(loginImage);
    }

    try {
      await updateSettings(data);
      dispatch(showSnackbar({ message: "Settings updated successfully", severity: "success" }));

      if (data.logo) {
        dispatch(setReduxLogo(data.logo));
      }
      if (data.login_image) {
        dispatch(setReduxLoginImage(data.login_image));
      }
    } catch (error) {
      dispatch(showSnackbar({ message: "Failed to update settings", severity: "error" }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `linear-gradient(20deg, ${primaryColor}, ${secondaryColor}, ${primaryColor})`,
      }}
    >
      <Backdrop open={isLoading} style={{ zIndex: 9999, color: "#fff" }}>
        <CircularProgress size={100} />
      </Backdrop>
      <Card>
        <CardContent>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                {t("settings.title")}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <InputLabel htmlFor="logo-upload">{t("settings.logoLabel")}</InputLabel>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  type="file"
                  id="logo-upload"
                  name="logo-upload"
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    setLogo(target.files ? target.files[0] : null);
                  }}
                />
                <InputLabel htmlFor="login-image-upload">{t("settings.loginImageLabel")}</InputLabel>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  type="file"
                  id="login-image-upload"
                  name="login-image-upload"
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    setLoginImage(target.files ? target.files[0] : null);
                  }}
                />
                <InputLabel htmlFor="change-country">{t("settings.changeCountry")}</InputLabel>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <Select
                    labelId="change-country"
                    sx={{ width: "100%", minWidth: 200 }}
                    value={country.code}
                    onChange={(e) => setCountry(getCountryByCode(e.target.value))}
                    renderValue={(selected) => {
                      const selectedCountry = SUPPORTED_COUNTRIES.find((country) => country.code === selected);
                      return selectedCountry ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Flag code={selectedCountry.flag} style={{ marginRight: 8 }} width={20} />
                          {selectedCountry.name}
                        </Box>
                      ) : (
                        <em>{t("settings.changeCountry")}</em>
                      );
                    }}
                  >
                    {SUPPORTED_COUNTRIES.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Flag code={country.flag} style={{ marginRight: 8 }} width={20} />
                          {country.name}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="primaryColor"
                  label={t("settings.primaryColorLabel")}
                  name="primaryColor"
                  type="color"
                  value={primaryColor}
                  onChange={handleInputChange(setReduxPrimaryColor)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="secondaryColor"
                  label={t("settings.secondaryColorLabel")}
                  name="secondaryColor"
                  type="color"
                  value={secondaryColor}
                  onChange={handleInputChange(setReduxSecondaryColor)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="tertiaryColor"
                  label={t("settings.tertiaryColorLabel")}
                  name="tertiaryColor"
                  type="color"
                  value={tertiaryColor}
                  onChange={handleInputChange(setReduxTertiaryColor)}
                />
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3 }}
                  onClick={handleSubmit}
                >
                  {t("settings.submit")}
                </Button>
              </Box>
            </Box>
          </Container>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SettingsPage;
