import React, { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useAppSelector } from "../hooks";
import MaijaRoute, {
  APPLICANT_LIST_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  JOB_COACH_DETAILS_ROUTE,
  navigateToJobCoachDetails,
  SENDOUT_CREATE_ROUTE,
  SETTINGS_ROUTE,
} from "../Routes";
import { useNavigate, useLocation } from "react-router-dom";
import { useLogout } from "../utils/useLogout";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Language, Menu as MenuIcon } from "@mui/icons-material";
import Flag from "react-world-flags";
import { ListItemIcon, ListItemText, Drawer, List, ListItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme, alpha } from "@mui/material/styles";
import { RootState } from "../store";
import dayjs from "dayjs";
import { AuthContext } from "../providers/AuthProvider";
import MaijaLoadingPage from "./MaijaLoadingPage";
import { SecurityLevel } from "../types/User";
import { appBarTextInactiveDark, appBarTextInactiveLight } from "./MaijaColors";

const languages = [
  { code: "sv", name: "Svenska", flag: "SE" },
  { code: "en", name: "English", flag: "GB" },
];

const getContrastColor = (hexColor: string) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
};

const getContrastColorInactive = (hexColor: string) => {
  return hexColor === "#000000" ? appBarTextInactiveDark : appBarTextInactiveLight;
};

interface MaijaAppBarProps {
  routes: MaijaRoute[];
}

const MaijaAppBar: React.FC<MaijaAppBarProps> = ({ routes }) => {
  const logout = useLogout();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const logo = useAppSelector((state: RootState) => state.theme.logo);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const tertiaryColor = useAppSelector((state: RootState) => state.theme.tertiaryColor);
  const tertiaryContrastTextColor = getContrastColor(tertiaryColor);
  const tertiaryContrastTextInactiveColor = getContrastColorInactive(tertiaryContrastTextColor);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElLang, setAnchorElLang] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const settingsLoaded = useAppSelector((state: RootState) => state.theme.settingsLoaded);
  const location = useLocation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    Cookies.set("language", lng, { expires: 365 });
    dayjs.locale(lng);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || Cookies.get("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const handleLogout = async () => {
    setIsLoading(true);
    await logout();
    navigate("/login");
    setIsLoading(false);
  };

  const settings = [
    ...(user && user.securityLevel >= SecurityLevel.CompanyAdmin
      ? [
          { id: "settings", name: t("settings.settings") },
          { id: "create-sendout", name: t("settings.create_sendout") },
        ]
      : []),
    { id: "change_password", name: t("settings.change_password") },
    { id: "logout", name: t("settings.logout") },
  ];

  if (isLoading || !settingsLoaded) {
    return <MaijaLoadingPage isFullscreen={true} />;
  }

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: tertiaryColor, minWidth: "100%" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ height: (theme) => theme.spacing(16) }}>
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => setDrawerOpen(!drawerOpen)}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography
              key="Logo"
              variant="h3"
              noWrap
              component="a"
              onClick={() => navigate(APPLICANT_LIST_ROUTE.path)}
              data-cy="app-bar-route.logo"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "flex" },
                fontFamily: "Lato Web",
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <img
                src={logo || "https://maija.blob.core.windows.net/company/MAIJA_V3.svg"}
                alt={logo ? "Company Logo" : "Default Logo"}
                style={{ height: "40px", width: "auto" }}
              />
            </Typography>
            {!isMobile && (
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                {routes.map((route, _) => {
                  const isActive = location.pathname === route.path;
                  return (
                    <Box
                      key={route.path}
                      onClick={() => {
                        if (route === JOB_COACH_DETAILS_ROUTE && user?.jobCoachId) {
                          navigateToJobCoachDetails(navigate, user?.jobCoachId);
                        } else {
                          navigate(route.path);
                        }
                      }}
                      data-cy={`app-bar-${route.nameKey}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        px: 4,
                        cursor: "pointer",
                        color: isActive ? tertiaryContrastTextColor : tertiaryContrastTextInactiveColor, // Highlight text when active
                        backgroundColor: isActive ? alpha(appBarTextInactiveLight, 0.2) : "transparent",
                        "&:hover": {
                          color: tertiaryContrastTextColor, // Highlight text on hover
                          backgroundColor: alpha(appBarTextInactiveLight, 0.1), // Optional hover background
                        },
                        transition: "color 0.3s, background-color 0.3s",
                      }}
                    >
                      {t(route.nameKey)}
                    </Box>
                  );
                })}
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Select language">
                <IconButton
                  onClick={(e) => setAnchorElLang(e.currentTarget)}
                  sx={{ p: 0, mr: 2 }}
                  data-cy="app-bar-route.language"
                >
                  <Avatar>
                    <Language />
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-lang"
                anchorEl={anchorElLang}
                open={Boolean(anchorElLang)}
                onClose={() => setAnchorElLang(null)}
                sx={{ mt: (theme) => theme.spacing(12) }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                {languages.map((lang) => (
                  <MenuItem
                    key={lang.code}
                    onClick={() => {
                      changeLanguage(lang.code);
                      setAnchorElLang(null);
                    }}
                    data-cy={`app-bar-route.language-${lang.code}`}
                  >
                    <ListItemIcon>
                      <Flag code={lang.flag} style={{ width: 24, height: 24 }} alt={lang.name} />
                    </ListItemIcon>
                    <ListItemText primary={lang.name} />
                  </MenuItem>
                ))}
              </Menu>

              <Tooltip title="Open settings" data-cy="app-bar-route.settings">
                <IconButton
                  onClick={(e) => setAnchorElUser(e.currentTarget)}
                  sx={{ p: 0 }}
                  data-cy="app-bar-route.settings"
                >
                  <Avatar alt={user?.fullName || "User"} src={user?.profileIconUrl || ""} />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={() => setAnchorElUser(null)}
                sx={{ mt: (theme) => theme.spacing(12) }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting.id}
                    onClick={() => {
                      switch (setting.id) {
                        case "logout":
                          handleLogout();
                          break;
                        case "change_password":
                          navigate(CHANGE_PASSWORD_ROUTE.path);
                          break;
                        case "settings":
                          navigate(SETTINGS_ROUTE.path);
                          break;
                        case "create-sendout":
                          navigate(SENDOUT_CREATE_ROUTE.path);
                          break;
                        default:
                          break;
                      }
                      setAnchorElUser(null);
                    }}
                    data-cy={`app-bar-route.settings-${setting.id}`}
                  >
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setDrawerOpen(false)}
          onKeyDown={() => setDrawerOpen(false)}
        >
          <List>
            {routes.map((route) => (
              <ListItem
                button
                key={route.path}
                onClick={() => {
                  if (route === JOB_COACH_DETAILS_ROUTE && user?.jobCoachId) {
                    navigateToJobCoachDetails(navigate, user?.jobCoachId);
                  } else {
                    navigate(route.path);
                  }
                }}
              >
                <ListItemText primary={t(route.nameKey)} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default MaijaAppBar;
