import React, { useEffect, useState } from "react";
import {
  Typography,
  AppBar,
  Tabs,
  Tab,
  Fab,
  Zoom,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import MaijaContainer from "../../components/MaijaContainer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useTranslation } from "react-i18next";
import {
  setMeetings,
  setSendOutTimes,
  setSendouts,
  setApplicantNames,
  setSelectedTab,
} from "../../reducers/planningPageSlice";
import { fetchMeetings, fetchSendOutTimes, fetchSendOuts, fetchApplicantNames } from "./PlanningRepository";
import { PlanningPageTab } from "./PlanningPageTabs";
import { MeetingsTab } from "./MeetingsTab";
import { SendOutTab } from "./SendOutTab";
import { CalendarTab } from "./CalendarTab";
import { BookingModal, BookingMode, BookingType } from "./BookModal";

export const PlanningPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const meetings = useAppSelector((state) => state.planningPageSlice.meetings);
  const sendoutTimes = useAppSelector((state) => state.planningPageSlice.sendoutTimes);
  const applicantNames = useAppSelector((state) => state.planningPageSlice.applicantNames);
  const selectedMeeting = useAppSelector((state) => state.planningPageSlice.selectedMeeting);
  const selectedSendOutTime = useAppSelector((state) => state.planningPageSlice.selectedSendOutTime);
  const tab = useAppSelector((state) => state.planningPageSlice.selectedTab);
  const jobCoachId = useAppSelector((state) => state.user.user?.jobCoachId);

  const [selectedApplicantFilter, setSelectedApplicantFilter] = useState<string>("");
  const [showPast, setShowPast] = useState<boolean>(false);

  useEffect(() => {
    fetchMeetings(jobCoachId).then((data) => dispatch(setMeetings(data)));
    fetchSendOutTimes(jobCoachId).then((data) => dispatch(setSendOutTimes(data)));
    fetchSendOuts().then((data) => dispatch(setSendouts(data || [])));
    fetchApplicantNames(jobCoachId).then((data) => dispatch(setApplicantNames(data)));
  }, [dispatch, jobCoachId]);

  const setPlanningTab = (newTab: PlanningPageTab) => {
    dispatch(setSelectedTab(newTab));
  };

  const now = new Date();

  let filteredMeetings = selectedApplicantFilter
    ? meetings.filter((m) => m.applicantFullName === selectedApplicantFilter)
    : meetings;

  let filteredSendOutTimes = selectedApplicantFilter
    ? sendoutTimes.filter((s) => s.applicantFullName === selectedApplicantFilter)
    : sendoutTimes;

  if (!showPast) {
    filteredMeetings = filteredMeetings.filter((m) => new Date(m.startTime) >= now);
    filteredSendOutTimes = filteredSendOutTimes.filter((s) => new Date(s.sendTimeStart) >= now);
  }

  return (
    <MaijaContainer>
      {tab !== PlanningPageTab.CalendarTab && <FabComponent tab={tab} />}
      <Typography variant="h4" gutterBottom>
        {t("planningPage.title")}
      </Typography>

      <Box mb={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <AppBar position="static" color="transparent" elevation={0} style={{ flex: 1, marginRight: 16 }}>
          <Tabs
            value={tab}
            onChange={(event, newValue) => setPlanningTab(newValue)}
            indicatorColor="primary"
            textColor="inherit"
          >
            <Tab label={t("planningPage.meetingsTab")} />
            <Tab label={t("planningPage.sendoutsTab")} />
            <Tab label={t("planningPage.calendarTab")} />
          </Tabs>
        </AppBar>

        <Typography variant="caption" sx={{ mt: 2, mr: 5 }} style={{ display: "block" }}>
          {t("planningPage.filterNote")}
        </Typography>

        <Box display="flex" alignItems="center">
          <FormControl variant="outlined" size="small" style={{ minWidth: 170, marginTop: 4, marginRight: 16 }}>
            <InputLabel>{t("planningPage.filterByApplicant")}</InputLabel>
            <Select
              label={t("planningPage.filterByApplicant")}
              value={selectedApplicantFilter || t("planningPage.showAll")}
              onChange={(e) =>
                setSelectedApplicantFilter(e.target.value === t("planningPage.showAll") ? "" : e.target.value)
              }
            >
              <MenuItem value={t("planningPage.showAll")}>
                <em>{t("planningPage.showAll")}</em>
              </MenuItem>
              {applicantNames.map((name) => (
                <MenuItem key={name.fullName} value={name.fullName}>
                  {name.fullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControlLabel
            control={<Checkbox checked={showPast} onChange={(e) => setShowPast(e.target.checked)} />}
            label={t("planningPage.showPast")}
          />
        </Box>
      </Box>

      {tab === PlanningPageTab.MeetingsTab && (
        <MeetingsTab meetings={filteredMeetings} selectedMeeting={selectedMeeting} />
      )}
      {tab === PlanningPageTab.SendoutsTab && (
        <SendOutTab sendOutTimes={filteredSendOutTimes} selectedSendOutTime={selectedSendOutTime} />
      )}
      {tab === PlanningPageTab.CalendarTab && (
        <CalendarTab meetings={filteredMeetings} sendOutTimes={filteredSendOutTimes} />
      )}
    </MaijaContainer>
  );
};

interface FabComponentProps {
  tab: PlanningPageTab;
}

const FabComponent: React.FC<FabComponentProps> = ({ tab }) => {
  const { t } = useTranslation();
  const [fabAnimationValue, setFabAnimationValue] = useState<PlanningPageTab>(PlanningPageTab.MeetingsTab);
  const [bookingModal, setBookingModal] = useState(false);

  useEffect(() => {
    setFabAnimationValue(tab);
  }, [tab]);

  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  let bookingType: BookingType | null = null;

  if (tab === PlanningPageTab.MeetingsTab) {
    bookingType = BookingType.Meeting;
  } else if (tab === PlanningPageTab.SendoutsTab) {
    bookingType = BookingType.SendOut;
  }

  return (
    <Box>
      {bookingType !== null && (
        <BookingModal
          booking={{ mode: BookingMode.Create, type: bookingType }}
          isOpen={bookingModal}
          handleClose={() => {
            setBookingModal(false);
          }}
        />
      )}

      <Zoom
        key={tab}
        in={fabAnimationValue === tab}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${fabAnimationValue === tab ? transitionDuration.exit : 0}ms`,
        }}
        unmountOnExit
      >
        <Fab
          variant="extended"
          color="secondary"
          style={{
            position: "fixed",
            bottom: "60px",
            right: "96px",
          }}
          onClick={() => setBookingModal(true)}
        >
          <AddIcon sx={{ mr: 1 }} />
          <>
            {tab === PlanningPageTab.MeetingsTab && (
              <Typography variant="body2" data-cy="add-meeting">
                {t("planningPage.addMeeting")}
              </Typography>
            )}
            {tab === PlanningPageTab.SendoutsTab && (
              <Typography variant="body2">{t("planningPage.addSendout")}</Typography>
            )}
          </>
        </Fab>
      </Zoom>
    </Box>
  );
};

export default PlanningPage;
